/**
 * axios封装
 * @author: MaJinFeng
 * @date：2020-7-1
 */
import axios from "axios";
import { ElLoading, ElMessage } from "element-plus";
import session from "../utils/session.js";
import baseUrl from "./constans";
// 显⽰loading
// 记录当前页⾯请求总次数
let loadingInstance = ''
let loadingRequestCount = 0;
const showLoading = () => {
  if (loadingRequestCount === 0) {
    loadingInstance = ElLoading.service({
      lock: true,
      text: '加载中...',
      spinner: '',
      background: 'rgba(0, 0, 0, 0.5)'
    });
  }
  loadingRequestCount++;
};
// 隐藏loading
const hideLoading = () => {
  if (loadingRequestCount <= 0) return;
  loadingRequestCount--;
  if (loadingRequestCount === 0) {
    loadingInstance.close();
  }
};
// let loadingInstance;
axios.defaults.headers = {
    "Content-Type": "application/json;charset=utf8",
};
axios.defaults.baseURL = baseUrl;
// 请求拦截器
axios.interceptors.request.use(
    config => {
        showLoading()
        const token = session.get("appoint-token");
        if (token) {
            config.headers["token"] = token;
        }
        config.headers["from"] = 'pc'
        return config;
    },
    error => {
        Promise.reject(error);
    }
);
// 返回拦截器
axios.interceptors.response.use(
    response => {
        if(response.data.code == 200){
            hideLoading();
        }else if (response.data.code == 401) {
            hideLoading();
            session.remove('appoint-token');
            ElMessage.error({message:'请登录!',duration:1000});
            window.location.href = '/'
        }else {
            hideLoading();
        }
        return response.data;
    },
    error => {
        hideLoading();
        return Promise.reject(error);
    }
);
const HTTP = {
    get: function (url, params) {
        return axios({
            url,
            method: "get",
            params: params,
        })
        .then(res => res)
        .catch(error => error.response);
    },
    post: function (url, params) {
        return axios({
            url,
            method: "post",
            data: params,
        })
        .then(res => res)
        .catch(error => error.response);
    },
    put: function (url, params) {
        return axios({
            url,
            method: "put",
            data: params,
        })
        .then(res => res)
        .catch(error => error.response);
    },
    delete: function (url, params) {
        return axios({
            url,
            method: "delete",
            data: params,
        })
        .then(res => res)
        .catch(error => error.response);
    },
    upload: function (url, params) {
        return axios({
            url,
            method: "post",
            data: params,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(res => res)
        .catch(error => error.response);
    },
    downloadFile: function (url, params) {
        return axios({
            url,
            method: "get",
            params: params,
            withCredentials: true,
            responseType: "blob",
        })
        .then(res => res)
        .catch(error => error.response);
    },
};
export default HTTP;
