//api.js
import HTTP from "@/api/axios";
const API = {
  // 验证码***
  send: function (data) {
    return HTTP.post("/api/sms/send", data);
  },
  // 账密登录***  
  login: function (data) {
    return HTTP.post("/api/user/login", data);
  },
  // 手机验证码登录***
  mobilelogin: function (data) {
    return HTTP.post("/api/user/mobilelogin", data);
  },
  // 注册***
  register: function (data) {
    return HTTP.post("/api/user/register", data);
  },
  // 退出登录***
  logout: function (data) {
    return HTTP.post("/api/user/logout", data);
  },
  // 广告列表***
  getAdv: function (data) {
    return HTTP.get("/api/adv", data);
  },
  // 个人资料***
  getUser: function (data) {
    return HTTP.get("/api/user", data);
  },
  // 系统设置***
  getSystem: function (data) {
    return HTTP.get("/api/common/system", data);
  },
  // 资料修改***
  userProfile: function (data) {
    return HTTP.post("/api/user/profile", data);
  },
  // 分类下拉***(调用部分)
  category: function (data) {
    return HTTP.post("/api/category", data);
  },
  // 科创产品列表***
  getproduct: function (data) {
    return HTTP.get("/api/product", data);
  },
  // 科创产品详情***
  productDetali: function (id) {
    return HTTP.get(`/api/product/${id}`);
  },
  // 科创产品评论列表***
  getproductClist: function (id,data) {
    return HTTP.get(`/api/product/clist/${id}`, data);
  },
  // 科创产品评论***
  productComment: function (id,data) {
    return HTTP.post(`/api/product/comment/${id}`,data);
  },
  // 科创产品预约***
  productReserve: function (id,data) {
    return HTTP.post(`/api/product/reserve/${id}`,data);
  },
  // 科创产品取消预约***
  productCancel: function (id) {
    return HTTP.get(`/api/product/cancel/${id}`);
  },
  // 科创产品收藏/取消收藏***
  productCollect: function (id) {
    return HTTP.get(`/api/product/collect/${id}`);
  },
  // 科创产品删除预约***
  productDelete: function (id) {
    return HTTP.delete(`/api/product/${id}`);
  },
  // 需求 - 列表***
  demand: function (data) {
    return HTTP.get("/api/demand",data);
  },
  // 需求 - 详情***
  demandDetail: function (id) {
    return HTTP.get(`/api/demand/${id}`);
  },
  // 专家智库 - 列表***
  expert: function (data) {
    return HTTP.get("/api/expert",data);
  },
  // 专家智库 - 详情***
  expertDetail: function (id) {
    return HTTP.get(`/api/expert/${id}`);
  },
  // 文章 - 列表***
  article: function (data) {
    return HTTP.get("/api/article",data);
  },
  // 文章 - 详情***
  articleDetail: function (id) {
    return HTTP.get(`/api/article/${id}`);
  },
  // 问题 - 列表***
  question: function (data) {
    return HTTP.get("/api/question",data);
  },
  // 问题 - 详情***
  questionDetail: function (id) {
    return HTTP.get(`/api/question/${id}`);
  },
  // 设备 - 列表***
  device: function (data) {
    return HTTP.get("/api/device",data);
  },
  // 设备 - 详情***
  deviceDetail: function (id) {
    return HTTP.get(`/api/device/${id}`);
  },
  // 手机号验证***
  validatemobile: function (data) {
    return HTTP.post("/api/user/validatemobile",data);
  },
  // 修改手机号***
  changemobile: function (data) {
    return HTTP.post("/api/user/changemobile",data);
  },
  // 重置密码***
  resetpwd: function (data) {
    return HTTP.post("/api/user/resetpwd",data);
  },
  // 找回密码***
  forgetpwd: function (data) {
    return HTTP.post("/api/user/forgetpwd",data);
  },
  // 我的需求-列表***
  demandlist: function (data) {
    return HTTP.get("/api/demand/list",data);
  },
  // 我的需求-详情***
  demandView: function (id) {
    return HTTP.get(`/api/demand/view/${id}`);
  },
  // 我的需求-发布***
  demandPost: function (data) {
    return HTTP.post("/api/demand",data);
  },
  // 我的需求-修改***
  demandPut: function (id,data) {
    return HTTP.put(`/api/demand/${id}`,data);
  },
  // 我的需求-删除***
  demandDel: function (id) {
    return HTTP.delete(`/api/demand/${id}`);
  },
  // 我的预约 - 列表***
  recordlist: function (data) {
    return HTTP.get("/api/product/recordlist",data);
  },
  // 我的预约 - 详情***
  recordproduct: function (id) {
    return HTTP.get(`/api/product/record/${id}`);
  },
  // 我的评论 - 列表***
  commentlist: function (data) {
    return HTTP.get("/api/product/commentlist",data);
  },
  // 我的收藏 - 列表
  collectlist: function (data) {
    return HTTP.get("/api/product/collectlist",data);
  },
  // 省市区联动***
  commonPcd: function (data) {
    return HTTP.get("/api/common/pcd",data);
  },
  // 省市区全部***
  commonArea: function (data) {
    return HTTP.get("/api/common/area",data);
  },
  // 成果发布 - 列表***
  achievementlist: function (data) {
    return HTTP.get("/api/achievement/list",data);
  },
  // 成果发布 - 详情***
  achievementView: function (id) {
    return HTTP.get(`/api/achievement/view/${id}`);
  },
  // 成果发布***
  achievement: function (data) {
    return HTTP.post("/api/achievement",data);
  },
  // 成果发布 - 修改***
  achievementPut: function (id,data) {
    return HTTP.put(`/api/achievement/${id}`,data);
  },
  // 成果发布 - 删除***
  achievementDele: function (id) {
    return HTTP.delete(`/api/achievement/${id}`);
  },
  // 首页***
  pageIndex: function (data) {
    return HTTP.get("/api/index",data);
  },
  // 成果推荐 - 列表***
  achievementTjlist: function (data) {
    return HTTP.get("/api/achievement",data);
  },
  // 成果推荐 - 详情***
  achievementTjDetail: function (id) {
    return HTTP.get(`/api/achievement/${id}`);
  },
  // 单页***
  pages: function (data) {
    return HTTP.get('/api/page',data);
  },
}

export default API