<template>
  <div>
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
import { nextTick, provide, ref } from "vue";
export default {
  name: "App",
  setup() {
    // 局部组件刷新
    const isRouterAlive = ref(true);
    const reload = () => {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    provide("reload", reload);

    return {
      isRouterAlive,
    };
  },
};
</script>
<style lang="less">
* {
  padding: 0;
  margin: 0;
  cursor:pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.l_h{
  line-height: 30px;
}
.l_hs{
  line-height: 20px;
}
.l_hs25{
  line-height: 25px;
}
.l_flex1{
  display:flex;
  flex: 1;
}
.l_flex{
  display: flex;
}
.l_flex_ai{
  align-items:center;
}
.l_flex_dc{
  flex-direction: column;
}
.l_flex_jc{
  justify-content: center;
}
.l_flex_jcSa{
  justify-content:space-around;
}
.l_flex_jcSb{
  justify-content:space-between;
}
.l_flex_r{
  flex-direction: row-reverse;
}
.l_fs_12{
  font-size:12px;
}
.l_fs_14{
  font-size:14px;
}
.l_fs_16{
  font-size:16px;
}
.l_fs_18{
  font-size:18px;
}
.l_fs_20{
  font-size:20px;
}
.l_fs_22{
  font-size:22px;
}
.l_fs_24{
  font-size:24px;
}
.l_fs_26{
  font-size:26px;
}
.l_fs_28{
  font-size:28px;
}
.l_fs_30{
  font-size:30px;
}
.l_fs_36{
  font-size:36px;
}
.l_fw_b{
  font-weight:bold;
}
.l_fat{
  text-align: center;
}
.l_fat_r{
  text-align: right;
}
.l_fc_1d{
  color: #1D92F3;
}
.l_fc_F57E49{
  color: #F57E49;
}
.l_fc_f{
  color: #fff;
}
.l_fc_6{
  color: #666;
}
.l_fc_9{
  color: #999;
}
.l_fc_3{
  color:#333;
}
.l_fc_0{
  color:#000;
}
.l_fc_red{
  color:#FF0000;
}
.l_fc_252B3A{
  color: #252B3A;
}
.l_bg_3177C5{
  background-color:#3177C5;
}
.l_p{
  padding:30px;
}
.l_pl{
  padding-left: 20px;
}
.l_pl3{
  padding-left: 30px;
}
.l_pls{
  padding-left:10px;
}
.l_plr{
  padding:0 20px;
}
.l_pt{
  padding-top: 10px;
}
.l_pt2{
  padding-top: 20px;
}
.l_pt3{
  padding-top: 30px;
}
.l_pbs{
  padding-bottom: 40px;
}
.l_pts{
  padding-top: 10px;
}
.l_mts{
  margin-top: 20px;
}
.l_mt{
  margin-top: 40px;
}
.l_mt30{
  margin-top: 30px;
}
.l_ml{
  margin-left:20px;
}
.l_ml4{
  margin-left:40px;
}
.l_mb{
  margin-bottom: 40px;
}
.l_mbs{
  margin-bottom:20px;
}
.l_tx{
  text-align: center;
}
.titleDot {
	position: relative;
  font-size: 20px;
  font-weight: bold;
	&::after {
		content: "";
		left: -7px;
		bottom:2px;
		width:4px;
		height:20px;
		background:#068EE7;
		position: absolute;
	}
}
.titleDotZy {
	position: relative;
	&::after {
		content: "";
		left: -12px;
		bottom:2px;
		width:7px;
		height:14px;
    background-image: url('@/assets/icon/lefts.png');
    background-size: 100% 100%;
		position: absolute;
	}
}
.l-price-scribe{
	font-weight:500;
	font-size: 36px;
	color: #068EE7;
	margin:0 0 2rpx 6rpx;
	&::after{
		content: '万';
    font-size:16px;
	}
}

.l-price-scribe24{
	font-weight:500;
	font-size: 24px;
	color: #068EE7;
	margin:0 0 2rpx 6rpx;
	&::after{
		content: '万';
    font-size:14px;
	}
}
.text-ellipsisDh{
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.text-ellipsis{
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.text-ellipsis2{
  overflow:hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// 滚动条样式
/* 全局样式，作用于所有滚动条 */
::-webkit-scrollbar {
  width: 4px; /* 垂直滚动条的宽度 */
  height: 10px; /* 水平滚动条的高度 */
}
 
/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 轨道颜色 */
}
 
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #CCCCCC; /* 滑块颜色 */
}
 
/* 滑块hover效果 */
::-webkit-scrollbar-thumb:hover {
  background: #CCCCCC; /* 滑块hover颜色 */
}
</style>
