import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  // 重定向到personalCenter页面,
  {
    path: '/',
    redirect:'/home',
    component:() => import('@/views/Home.vue'),
    children:[
      {
        path: 'home',
        component: () => import('@/views/homePage/index.vue'),
        meta:{isHideHeader:true},
      },
      {
        path: 'kcsc',
        component: () => import('@/views/homePage/kechuangMall.vue'),
      },
      {
        path: 'kcscDetail',
        component: () => import('@/views/homePage/kechuangMallDetail.vue'),
      },
      {
        path: 'publishing',
        component: () => import('@/views/homePage/publishing.vue'),
      },
      {
        path: 'publishingDetail',
        component: () => import('@/views/homePage/publishingDetail.vue'),
      },
      {
        path: 'achievement',
        component: () => import('@/views/homePage/achievement.vue'),
      },
      {
        path: 'achievementDetail',
        component: () => import('@/views/homePage/achievementDetail.vue'),
      },
      {
        path: 'specialist',
        component: () => import('@/views/homePage/specialist.vue'),
      },
      {
        path: 'specialistDetail',
        component: () => import('@/views/homePage/specialistDetail.vue'),
      },
      {
        path: 'policy',
        component: () => import('@/views/homePage/policy.vue'),
      },
      {
        path: 'matterDetails',
        component: () => import('@/views/homePage/matterDetails.vue'),
      },
      {
        path: 'matterDetailsTwo',
        component: () => import('@/views/homePage/matterDetailsTwo.vue'),
      },
      {
        path: 'laboratory',
        component: () => import('@/views/homePage/laboratory.vue'),
      },
      {
        path: 'laboratoryDetails',
        component: () => import('@/views/homePage/laboratoryDetails.vue'),
      },
    ]
  },
  {
    path: '/mine',
    redirect:'/mine/my',
    component:() => import('@/views/homePage/mine/index.vue'),
    children:[
      {
        path: 'my',
        component: () => import('@/views/homePage/mine/minechild/index.vue'),
      },
      {
        path: 'security',
        component: () => import('@/views/homePage/mine/minechild/security.vue'),
      },
      {
        path: 'editModify',
        component: () => import('@/views/homePage/mine/minechild/editModify.vue'),
      },
      {
        path: 'installPhone',
        component: () => import('@/views/homePage/mine/minechild/installPhone.vue'),
      },
      {
        path: 'editPass',
        component: () => import('@/views/homePage/mine/minechild/editPass.vue'),
      },
      {
        path: 'needPublish',
        component: () => import('@/views/homePage/mine/minechild/needPublish.vue'),
      },
      {
        path: 'publish',
        component: () => import('@/views/homePage/mine/minechild/publish.vue'),
      },
      {
        path: 'postDemand',
        component: () => import('@/views/homePage/mine/minechild/postDemand.vue'),
      },
      {
        path: 'mineAchievement',
        component: () => import('@/views/homePage/mine/minechild/mineAchievement.vue'),
      },
      {
        path: 'publishResults',
        component: () => import('@/views/homePage/mine/minechild/publishResults.vue'),
      },
      {
        path: 'subscriBerecord',
        component: () => import('@/views/homePage/mine/minechild/subscriBerecord.vue'),
      },
      {
        path: 'MyAssessment',
        component: () => import('@/views/homePage/mine/minechild/MyAssessment.vue'),
      },
      {
        path: 'myCollection',
        component: () => import('@/views/homePage/mine/minechild/myCollection.vue'),
      },
      {
        path: 'callCenter',
        component: () => import('@/views/homePage/mine/minechild/callCenter.vue'),
      },
      {
        path: 'releaseOfResults',
        component: () => import('@/views/homePage/mine/minechild/releaseOfResults.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component:() => import('@/views/Login.vue'),
  },
  {
    path: '/singlePage',
    name: 'singlePage',
    component:() => import('@/views/singlePage.vue'),
  }
]
const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
export default router
